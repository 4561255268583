import Eye from './eye.svg';
import Dot from './dot.svg';
import Pin from './pin.svg';
import Faq from './faq.svg';
import Play from './play.svg';
import Menu from './menu.svg';
import Edit from './edit.svg';
import Chat from './chat.svg';
import Logo from './logo.svg';
import User from './user.svg';
import Info from './info.svg';
import Bell from './bell.svg';
import Clip from './clip.svg';
import Lock from './lock.svg';
import Like from './like.svg';
import Flag from './flag.svg';
import Copy from './copy.svg';
import Baby from './baby.svg';
import Cross from './cross.svg';
import Share from './share.svg';
import Gmail from './gmail.svg';
import Inbox from './inbox.svg';
import Check from './check.svg';
import Video from './video.svg';
import Close from './close.svg';
import Offer from './offer.svg';
import Sleep from './sleep.svg';
import Reply from './reply.svg';
import Clock from './clock.svg';
import Forum from './forum.svg';
import Unlock from './unlock.svg';
import Expert from './expert.svg';
import Search from './search.svg';
import Folder from './folder.svg';
import Logout from './logout.svg';
import Filter from './filter.svg';
import Camera from './camera.svg';
import Pencil from './pencil.svg';
import Medical from './medical.svg';
import Bookmark from './bookmark.svg';
import Handout from './handout.svg';
import Courses from './courses.svg';
import Twitter from './twitter.svg';
import Message from './message.svg';
import Outlook from './outlook.svg';
import Pregnant from './pregnant.svg';
import Location from './location.svg';
import Discount from './discount.svg';
import ItemPlay from './item-play.svg';
import Magazine from './magazine.svg';
import Calendar from './calendar.svg';
import Facebook from './facebook.svg';
import Checkbox from './checkbox.svg';
import Settings from './settings.svg';
import SadSmile from './sad-smile.svg';
import ChatInfo from './chat-info.svg';
import FileEdit from './file-edit.svg';
import LoadMore from './load-more.svg';
import Insurance from './insurance.svg';
import Community from './community.svg';
import Instagram from './instagram.svg';
import Attention from './attention.svg';
import CheckLock from './check-lock.svg';
import ItemCheck from './item-check.svg';
import ChevronUp from './chevron-up.svg';
import EmailCheck from './email-check.svg';
import PhoneCheck from './phone-check.svg';
import LogoSimple from './logo-simple.svg';
import StarFilled from './star-filled.svg';
import CoursesLogo from './courses-logo.svg';
import ChevronLeft from './chevron-left.svg';
import EnglishFlag from './english-flag.svg';
import ChevronDown from './chevron-down.svg';
import InputSearch from './input-search.svg';
import IdealPayment from './idealPayment.svg';
import ChevronRight from './chevron-right.svg';
import PlayBoardered from './play-boardered.svg';
import CalendarCheck from './calendar-check.svg';
import SecurePayment from './secure-payment.svg';
import CalendarSimple from './calendar-simple.svg';
import ExpertLocation from './expert-location.svg';
import CoursesDashboard from './courses-dashboard.svg';
import CoursesChevronLeft from './courses-chevron-left.svg';
import CoursesChevronRight from './courses-chevron-right.svg';

export default {
  Dot,
  Eye,
  Pin,
  Faq,
  Menu,
  Edit,
  Chat,
  Play,
  Logo,
  Copy,
  User,
  Info,
  Bell,
  Clip,
  Lock,
  Baby,
  Cross,
  Flag,
  Like,
  Share,
  Gmail,
  Reply,
  Inbox,
  Check,
  Close,
  Offer,
  Sleep,
  Clock,
  Forum,
  Video,
  Search,
  Folder,
  Logout,
  Filter,
  Camera,
  Pencil,
  Unlock,
  Expert,
  Handout,
  Courses,
  Message,
  Outlook,
  Twitter,
  Medical,
  Location,
  SadSmile,
  Settings,
  Bookmark,
  ChatInfo,
  Magazine,
  FileEdit,
  Facebook,
  Calendar,
  Checkbox,
  Pregnant,
  LoadMore,
  Discount,
  ItemPlay,
  ItemCheck,
  CheckLock,
  Community,
  Insurance,
  Instagram,
  ChevronUp,
  Attention,
  PhoneCheck,
  EmailCheck,
  LogoSimple,
  StarFilled,
  CoursesLogo,
  ChevronDown,
  ChevronLeft,
  EnglishFlag,
  InputSearch,
  IdealPayment,
  ChevronRight,
  CalendarCheck,
  PlayBoardered,
  SecurePayment,
  CalendarSimple,
  ExpertLocation,
  CoursesDashboard,
  CoursesChevronLeft,
  CoursesChevronRight
};
